import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
type ActionMenuProps = {
  handleClick: (event: any) => void;
  canAccess: boolean;
  active: boolean;
};
const ActionMenu = ({ canAccess, active, handleClick }: ActionMenuProps) => {
  const menuItems = [];
  if (!canAccess) {
    menuItems.push({ key: "gPass", label: "Create Login Account" });
  } else {
    menuItems.push({
      key: active ? "deactivate" : "activate",
      label: active ? "Deactivate" : "Activate",
    });
    menuItems.push({ key: "changePass", label: "Change Password" });
    menuItems.push({ key: "roles", label: "Add Role" });
    menuItems.push({ key: "revoke", label: "Revoke Active Logins" });
  }
  const menu = <Menu onClick={handleClick} items={menuItems} />;
  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <Button block type="primary" size="middle">
        Actions
      </Button>
    </Dropdown>
  );
};

export default ActionMenu;
