
import Tag from "antd/es/tag";
import Row from "antd/es/row";
import Space from "antd/es/space";
import Descriptions from "antd/es/descriptions";


import { ResponseType } from "pages/employees";
import * as api from "components/api";
import { ILookup } from "components/api";
import { PageHeader } from "@ant-design/pro-layout";
import { ProCard } from '@ant-design/pro-components';
import ActionMenu from "./action_menu";

type profileHeaderProps = {
  data: ResponseType;
  jobTitle: ILookup | undefined;
  options: Record<api.ILookupKeys, api.ICombo[]>;
  title: string;
  handler: (event: any) => void;
};

export const ProfileHeader = ({
  data,
  title,
  options,
  jobTitle,
  handler,
}: profileHeaderProps) => {
  return (
    <PageHeader
      onBack={() => window.history.back()}
      title={title}
      subTitle={`${data.fullName} ${data.lastName}`}
      tags={
        <Space>
          <Tag color={data.active ? "green" : "volcano"}>
            Active: {data.active ? "Yes" : "No"}
          </Tag>

          <Tag color={jobTitle ? "green" : "volcano"}>
            Job Title: {jobTitle?.name || "None"}
          </Tag>
        </Space>
      }
      extra={
        <Space>
          <ActionMenu
            handleClick={handler}
            active={data.active}
            canAccess={data.hasUser}
          />
        </Space>
      }
    >

      <ProCard
        title="User Detail"
        split={'vertical'}

        headerBordered
      >
        <ProCard colSpan="50%">
          <Row>
            <Descriptions size="default" column={1}>
              <Descriptions.Item label="First Name">
                {data.fullName}
              </Descriptions.Item>
              <Descriptions.Item label="Last Name">
                {data.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
              <Descriptions.Item label="Phone">{data.phone}</Descriptions.Item>
              <Descriptions.Item label="Gender">
                {data.sex.name}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </ProCard>

        <ProCard >
          <Row>
            <Descriptions size="default" column={1}>
              <Descriptions.Item label="Has User">
                {data.hasUser ? "Yes" : "No"}
              </Descriptions.Item>
              <Descriptions.Item label="Company">
                {data.company.name}
              </Descriptions.Item>
              <Descriptions.Item label="CreatedBy">-</Descriptions.Item>
              <Descriptions.Item label="CreatedOn">-</Descriptions.Item>
            </Descriptions>
          </Row>
        </ProCard>
      </ProCard>

    </PageHeader>
  );
};
