import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Space from "antd/lib/space";
import Popconfirm from "antd/es/popconfirm";
import Button from "antd/es/button";
import DeleteIcon from "@ant-design/icons/DeleteOutlined";
import {
  ICombo,
  ILookupKeys,
  useFetchSingleItem,
  Endpoints,
} from "components/api";
import { MenuActions } from "./index";
import { RoleRequestType } from "./roles-form";
type TableProps = {
  lookups: {
    data: Record<string, ICombo[]>;
    isloading: boolean;
  };
  employeeId: string | null;
  deleteHandler: (id: string, type: MenuActions) => void;
  editHandler: (payload: RoleRequestType, type: MenuActions) => void;
};
type ResponseType = {
  id: string;
  name: string;
};
const Table = (props: TableProps) => {
  const endpoint = `${Endpoints.Employee.roles(props.employeeId!)}`;
  const { data, isError, refetch, mainQueryKey } = useFetchSingleItem<ResponseType[]>({
    endpoint,
    enabled: true,
    ids: null,
  });
  const columns = useCallback(
    (
      lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Operations",
          width: 200,
          key: "options",
          valueType: "option",
          render: (_, record) => {
            return (
              <Space size="middle">
                <Popconfirm
                  title={`Are you sure to delete the selected role from the system?`}
                  onConfirm={() => props.deleteHandler(record.id, "roles")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteIcon />}
                    title="Delete"
                  />
                </Popconfirm>
              </Space>
            );
          },
        },
      ];
    },
    [props]
  );
  if (isError || !data) {
    return <div>Error</div>;
  }
  return (
    <ProTable<ResponseType>
      columns={columns(props.lookups.data, props.lookups.isloading)}
      loading={false}
      request={async () => data?.data}
      dataSource={data?.data ?? []}
      rowKey="id"
      search={false}
      dateFormatter="string"
      pagination={false}
    />
  );
};

export default Table;
