import { createContext } from "react";

interface IAuthState {
  user: any;
  accessToken: string;
  isAuthenticated: boolean;
  logoutHandler: () => void;
}

const AuthContext = createContext<IAuthState>({
  user: null,
  accessToken: "",
  isAuthenticated: false,
  logoutHandler: () => {},
});

export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;
export { AuthContext };
