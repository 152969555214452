import { ConfigProvider } from "antd";
import { AuthProvider, useAuth } from "./components/auth";
import Routes from "./components/routes";
import { LoadingPage, LoginPage } from "pages/common";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import enUS from "antd/lib/locale/en_US";


var queryClient = new QueryClient();



const App = () => {
  const { state, logoutFC, loginFC, loginProgress, } = useAuth();
  const showLoading = state.isLoading && !state.isAuthenticated;
  const showLogin = !state.isLoading && !state.isAuthenticated && !state.loginForm;
  if (showLoading) {
    return <LoadingPage />;
  }

  if (showLogin) {
    return <LoginPage loading={loginProgress} loginHandler={loginFC} />;
  }

  return (
    <AuthProvider
      value={{
        accessToken: state.token,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        logoutHandler: logoutFC,
      }}
    >
      <ConfigProvider theme={{
        token: {
          colorPrimary: "#198b19",
          borderRadius: 5,
        },
        components: {
          Breadcrumb: {
            separatorColor: "#198b19",
          },

        }
      }} locale={enUS}>
        <QueryClientProvider client={queryClient}>
          <Routes />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ConfigProvider>
    </AuthProvider>
  );
};

export default App;
