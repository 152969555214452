import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Popconfirm from "antd/es/popconfirm";
import EditButton from "@ant-design/icons/EditOutlined";
import DeleteButton from "@ant-design/icons/DeleteOutlined";
import ViewButton from "@ant-design/icons/EyeOutlined";
import tableExport from "antd-table-export";

import { ResponseType, TableProps } from "./types";
import { ICombo, ILookupKeys } from "components/api";

const Table = (props: TableProps) => {
  const navigate = useNavigate();
  const { isLoading, total, pageSize, dataSource, SendRequestForData } = props;
  const columns = useCallback(
    (
      lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [
        {
          title: "Full Name",
          dataIndex: "fullName",
          hideInSearch: true,
        },
        {
          title: "Gender",
          dataIndex: "gender",
          valueType: "select",
          request: async () => lookups["sex"],
          render: (_, { sex }) => sex.name,
          order: 1,
        },
        {
          title: "Email",
          dataIndex: "email",
        },
        {
          title: "Phone",
          dataIndex: "phone",
        },
        {
          title: "Has User",
          dataIndex: "hasUser",
          render: (_, { hasUser }) => hasUser ? "Yes" : "No",
          hideInSearch: true,
        },
        {
          title: "Active",
          dataIndex: "active",
          valueType: "switch",
          render: (_, { active }) => (active ? "Yes" : "No"),
        },
        {
          title: "Operations",
          width: 200,
          key: "options",
          valueType: "option",
          render: (_, record) => {
            const showActions =
              record.id !== "6123eb13-9d96-4eec-a275-e5a1c93f8852";
            return showActions ? (
              <Space size="middle">
                <Button
                  onClick={() =>
                    navigate({
                      pathname: "details",
                      search: "id=" + record.id,
                    })
                  }
                  type="default"
                  icon={<ViewButton />}
                  title="Details"
                />
                <Popconfirm
                  title={`Are you sure to  delete "${record.fullName ?? "this"
                    }" from the system?`}
                  onConfirm={() => props.deleteHandler(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteButton />}
                    title="Delete"
                  />
                </Popconfirm>
                <Button
                  onClick={() => props.editHandler(record)}
                  type="primary"
                  icon={<EditButton />}
                  title="Edit"
                />
              </Space>
            ) : null;
          },
        },
      ];
    },
    [navigate, props]
  );
  const renderedColumns = columns(props.lookups.data, props.lookups.isloading);

  const exportInstance = new tableExport(dataSource.map(v => ({
    ...v,
    gender: v.sex.name,
    active: v.active ? "Yes" : "No",
  })),
    renderedColumns.filter(x => x.key !== 'options').map(x => ({
      dataIndex: x.dataIndex as string,
      title: x.title! as string
    }))
  );
  return (
    <ProTable<ResponseType>
      columns={renderedColumns}
      loading={isLoading}
      request={async (params = {}, sort, filter) =>
        SendRequestForData({ params, sort, filter })
      }
      onReset={() => {
        let params = { current: 1, pageSize };
        let sort = {};
        let filter = {};
        SendRequestForData({ params, sort, filter, isReset: true });
      }}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        showQuickJumper: true,
        pageSize,
        total,
      }}
      search={{ labelWidth: "auto" }}
      dateFormatter="string"
      toolBarRender={() => [
        <Button type="primary" onClick={() => exportInstance.download("Employees List", "xlsx")}>
          Export
        </Button>,
        <Button type="primary" onClick={props.addNewHandler}>
          Add New
        </Button>,
      ]}
    />
  );
};

export default Table;
