import { useState } from "react";

import { Checkbox, Form, Input, Button, Col, Typography, Image } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { LoginForm } from "@ant-design/pro-components";

import Logo from "assets/images/DahabshiilGreen.png";




// import CarFilled from "@ant-design/icons/SecurityScanFilled";
import { loginFormType, IResponse, IErrorResult } from "components/api";
import { useNavigate } from "react-router-dom";



const { Text, Link } = Typography;
const Login = ({
  loginHandler,
  loading,
}: {
  loginHandler: (values: loginFormType) => Promise<IResponse<any>>;
  loading: boolean;
}) => {
  const [state, setState] = useState<{
    isError: boolean;
    error?: IErrorResult;
  }>({
    isError: false,
  });

  const errorsDev = () => {
    const { isError, error } = state;

    if (!isError) {
      return null;
    }
    if (error) {

      return (
        <Text style={{ display: 'flex', justifyContent: 'center' }} type="danger">{
          error.detail
        }</Text>
      );
    }
  };
  return (
    <Col span={24} style={{ display: "flex", justifyContent: "center", paddingTop: '10%' }}>

      <LoginForm <loginFormType>
        // logo={<CarFilled style={{ fontSize: "40px" }} />}

        title={<Text  >Badbaado</Text>}
        subTitle="Welcome to Badbaado Portal"
        loading={loading}
        onFinish={async (values) => {
          console.log("onfinish", values);
          const { data, isError } = await loginHandler(values);
          console.log("onfinish", isError);
          if (isError) {
            setState({ isError: true, error: data as IErrorResult });
            return;
          }
          //navigate("/otp", { state: values });
        }}

        className="login-form"
        submitter={{
          render: ({ submit }) => [
            <Button
              loading={loading}
              size="large"
              type="primary"
              color="danger"
              block
              onClick={submit}
            >
              Login
            </Button>,
          ],
        }}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            aria-placeholder="Password"

          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" href="">
            Forgot password
          </a>
        </Form.Item>
        {errorsDev()}
      </LoginForm>
    </Col>
  );
};

export default Login;
