import { AxiosError } from "axios";
import { IErrorResult } from "components/api/types";

export const normalizeErrors = (error: AxiosError): IErrorResult => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { status, data } = error.response;
    return {
      type: "Response_Exception",
      status,
      detail: '',
      title: '',
      instance: '',
      traceId: '',
      errors: data as Record<string, string[]> ,
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    return {
      type: "Request_Exception",
      status: 0,
      detail: "Make sure you are connected to the internet!",
      title: "We can't receive any response from the server!",
      instance: "page",
      traceId: "1",
      errors: undefined,
    };
  } 
  else {
    // Something happened in setting up the request that triggered an Error
    return {
      type: "Cancelation_Exception",
      status: 0,
      detail: "Please report back to us if this happens again",
      title:
        "It seems that the request didn't completed, or my be canceled for some reason",
      instance: "page",
      traceId: "1",
      errors: undefined,
    };
  }
};
