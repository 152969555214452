import { useState } from "react";
import { ModalForm, ProFormText } from "@ant-design/pro-form";
import message from "antd/es/message";

import Error from "components/ui/error";
import { ICombo, ISubmitionResponse } from "components/api";

export type ActionsRequestType = {
  password: string;
  passwordConfirmation: string;
};

type FormProps = {
  visible: boolean;
  onClose: (visible: boolean) => void;
  lookups: Record<string, ICombo[]>;
  onFinish: (values: ActionsRequestType) => Promise<ISubmitionResponse>;
  title: string;
};

const ActionsForm = (props: FormProps) => {
  const { visible, onClose, onFinish } = props;
  const [formErrors, setFormErrors] = useState<ISubmitionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  return (
    <ModalForm<ActionsRequestType>
      title={props.title}
      size={"middle"}
      width={400}
      visible={visible}
      autoFocusFirstInput
      request={async () => ({ password: "", passwordConfirmation: "" })}
      name="galawo-model-form"
      modalProps={{
        onCancel: () => onClose(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        var result = await onFinish(values);
        setFormErrors(result);
        if (result.isSuccess) {
          message.success("Successfuly completed");
          onClose(false);
        }
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormText.Password
        name="password"
        label="Password"
        placeholder="Enter Password"
        rules={[{ min: 8, max: 50, required: true }]}
        width="md"
      />
      <ProFormText.Password
        name="passwordConfirmation"
        label="Confirm Password"
        placeholder="Enter Password Confirmation"
        rules={[{ min: 8, max: 50, required: true }]}
        width="md"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </ModalForm>
  );
};

export default ActionsForm;
