
import jwt_decode, { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import pRetry, { AbortError } from "p-retry";
import axios, { AxiosError } from "axios";
import { tokenInStorageKey, tokenRefreshleeway, baseUrl } from "assets";
import type { IUser, ITokens } from ".";
import { normalizeErrors } from "utils";
import type { IResponse, IErrorResult, loginFormType } from "components/api";

interface IState {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: IUser | undefined;
  loginForm?: loginFormType | undefined;
  token: string;
  otp?: string;
}

class AuthService {
  _timeInterval: any;
  _stateListeners: any;
  tokens: ITokens | undefined;
  state: IState;
  constructor() {
    this.state = {
      isAuthenticated: false,
      isLoading: true,
      user: undefined,
      token: "",
    };
    this.tokens = undefined;
    this._stateListeners = [];
    this.LoadTokensFromLocalStorage();
  }

  private addStatusListener(listener: any) {
    this._stateListeners.push(listener);
  }

  //Components need to unsubscribe from changes when they unmount
  removeStatusListener(listener: any) {
    this._stateListeners = this._stateListeners.filter(
      (cb: any) => cb !== listener
    );
  }

  notifyListeners = () =>
    this._stateListeners.forEach((listener: any) => listener(this.state));

  unMount = (listener: any) => {
    clearInterval(this._timeInterval);
    this.removeStatusListener(listener);
  };

  private checkIfTokenWillExpire = () => {

    try {
      if (!this.tokens) {
        return false;
      }
      const decoded = jwtDecode(this.tokens.token);
      if (decoded.exp === undefined || decoded.exp === null) {
        return false;
      }
      const dateNow = new Date();
      const isValid = decoded.exp * 1000 > dateNow.getTime();
      return isValid;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  private decodeToken = (): IUser | undefined => {
    try {
      if (!this.tokens) {
        return undefined;
      }


      return jwtDecode<IUser>(this.tokens?.token) as IUser;
    } catch (error) {
      return undefined;
    }
  };

  // else load state from storage and check if expired
  public startUp = async (listener: any) => {
    this.addStatusListener(listener);
    if (!this.tokens) {
      return this.logout();
    }
    try {
      // if (!(await this.validateTokenRunner())) {
      //   // return this.logout();
      // }
      const isExpired = this.checkIfTokenWillExpire();
      if (!isExpired && this.tokens) {
        this.refreshTokenRunner();
      } else {
        this.refreshedOrLogined(this.tokens);
      }
    } catch (error) {
      this.logout();
    }
  };
  private LoadTokensFromLocalStorage = () => {
    try {
      const tokens = localStorage.getItem(tokenInStorageKey);
      if (tokens) {
        const { token, refreshToken } = JSON.parse(tokens) as ITokens;
        if (token && refreshToken) {
          this.tokens = { token, refreshToken };
          this.state.user = this.decodeToken();
        }
      }
    } catch (error) {}
  };

  refreshTokenRunner = async () => {
    try {
      await pRetry(this.refreshToken, {
        onFailedAttempt: (error) => {
          // error can be logged if we want
          console.log("invalidToken", error);
          this.logout();
        },
        retries: 2,
      });
    } catch (error) {
      this.logout();
    }
  };

  // validateTokenRunner = async () =>
  //   await pRetry(this.validateToken, {
  //     onFailedAttempt: (error) => {
  //       // error can be logged if we want
  //       console.log("invalidToken", error);
  //       this.logout();
  //     },
  //     retries: 2,
  //   });

  refreshToken = async () => {
    // console.log("interval:", this._timeInterval);
    try {
      const { data } = await axios.post<IResponse<ITokens>>(
        `${baseUrl}/auth/refresh/`,
        this.tokens
      );
      if(data.isError)
      {
        throw new Error(data.error);
      }
      this.refreshedOrLogined(data.data);
    } catch (error) {
      const _error = normalizeErrors(error as AxiosError);
      if (_error.status === 400) {
        throw new AbortError("Token is not valid");
      }
      throw new Error(_error.title);
    }
  };
  persistToken = (tokens: ITokens): boolean => {
    this.tokens = tokens;


    const decoded = this.decodeToken();

    if (decoded) {
      this.state = {
        isAuthenticated: true,
        isLoading: false,
        user: decoded,
        token: tokens.token,
      };
      this.setRefreshTimer(decoded.exp);
      return true;
    }
    return false;
  };
  setRefreshTimer = (exp: number) => {
    if (this._timeInterval !== undefined) {
      clearInterval(this._timeInterval);
    }
    let expiresIn = dayjs.unix(exp).diff(dayjs()) - tokenRefreshleeway.ms;
    //this._timeInterval = setInterval(this.refreshTokenRunner, expiresIn);
  };
  validateToken = async (): Promise<boolean> => {
    try {
      await axios.post(
        `${baseUrl}/auth/refresh/`,
        this.tokens,
      );
      return true;
    } catch (error) {
      const _error = normalizeErrors(error as AxiosError);
      if (_error.status === 400) {
        throw new AbortError("Token is not valid");
      }
      throw new Error(_error.title);
    }
  };

  private saveTokens = () => {
    localStorage.setItem(tokenInStorageKey, JSON.stringify(this.tokens));
  };
  refreshedOrLogined = (tokens: ITokens) => {
    var result = this.persistToken(tokens);
    if (result) {
      this.saveTokens();
      this.notifyListeners();
    }
  };
  sendOtp = async (payload: loginFormType): Promise<IResponse<boolean |IErrorResult>> => {
    try{
      const { data, status } = await axios.post<IResponse<boolean>>(`${baseUrl}/auth/sendOtp`,payload);
      if (status === 200) {
        if(data.isError)
        {
          return { isError: true, data: { detail: data.error,status :404} as IErrorResult };
        }
        this.state.loginForm = payload;
        return { isError: false, data: data.data };
      }
    } catch (error) {
      return { isError: true, data: normalizeErrors(error as AxiosError) };
    }

    return { isError: false, data: false };
  }


  login = async (
    payload: loginFormType
  ): Promise<IResponse<IErrorResult | ITokens | null>> => {
    try {
      debugger;
      const { data, status } = await axios.post<IResponse<ITokens>>(
        `${baseUrl}/auth/login`,
        {...payload, }
      );
      if (status === 200) {

        if(data.isError)
        {
          return { isError: true, data: { detail: data.error,status :404} as IErrorResult };
        }
        this.refreshedOrLogined(data.data);
        return { isError: false, data: data.data };
      }
    } catch (error) {
      return { isError: true, data: normalizeErrors(error as AxiosError) };
    }
    return { isError: false, data: null };
  };

  logout = () => {
    localStorage.removeItem(tokenInStorageKey);
    this.tokens = undefined;
    this.state = {
      isLoading: false,
      isAuthenticated: false,
      user: undefined,
      token: "",
    };

    this.notifyListeners();
  };
}

export default AuthService;
