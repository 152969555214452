import { DefaultFooter } from "@ant-design/pro-layout";
import { baseTitle } from "assets";
const Footer = () => {
  return (
    <DefaultFooter
      links={[{ key: "home", title: baseTitle, href: "www.dahabshiil.com" }]}
      copyright={baseTitle}
    />
  );
};

export default Footer;
