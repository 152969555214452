import { useNavigate } from "react-router-dom";
import Result from "antd/es/result";
import Button from "antd/lib/button";

type loadingProps = {
  page: "HR" | "Procurement" | "Finance" | "Projects";
};
const ErrorPage = ({ page }: loadingProps) => {
  const navigate = useNavigate();
  return (
    <Result
      status={"info"}
      title={"Welcome"}
      subTitle={`${page} Module`}
      extra={
        <Button onClick={() => navigate("/")} type="primary">
          Back Home
        </Button>
      }
    />
  );
};

export default ErrorPage;
