import { useState } from "react";
import { useLocation } from "react-router-dom";
import message from "antd/lib/message";
import {
  Endpoints
} from "components/api";
import * as api from "components/api";
import { ProfileHeader } from "./header";
import { ResponseType } from "pages/tasks";
import DetailsTab from "./tabs";
import { PopUpActions } from "pages/common";
import { ILookup } from "components/api";
import ActionsForm, { CustomerEntryRequest } from "./customer-entry-form";
import { set } from "lodash";
import CustomerContactForm, { CustomerContactRequest } from "./customer-contact-form";
export type MenuActions =
  | "add"
  | "edit"
  | "delete"
  | "done"
  | "inprogress"
  | "addContact"


export type JobType = {
  salary: number;
  startDate: Date;
  endDate: Date;
  job: ILookup;
  type: ILookup;
};
const lookupQueries: api.ILookupQueryProps[] = [
  {
    endpoint: "sex",
    fromLookup: false,
    isLocalEnum: true,
    id: "sex",
  },
];
type PopUpType = {
  id: number | string;
  title: string;
  visible: boolean;
  type: "activate" | "inprogress" | "done";
};
const Index = () => {
  const params = new URLSearchParams(useLocation().search);
  const endpoint = api.Endpoints.Customers.main;
  const customerId = params.get("id");

  const [taskForm, setTaskForm] = useState({
    visible: false,
    payload: {
      id: 0,
      taskActivityId: customerId!,
      description: "",
      priority: 0,
      startDate: null,
      employeeId: null,
      endDate: null,
    },
    type: "add",
  });

  const [customerContactForm, setCustomerContactForm] = useState({
    visible: false,
    payload: {
      id: 0,
      name: "",
      phoneNumber: "",
      email: "",
      address: "",
      sex: 0,
    },
    type: "addContact",
  });



  const [showPopup, setShowPopup] = useState<PopUpType>({
    id: "",
    title: "",
    visible: false,
    type: "activate",
  });
  const options = api.useLookups({ lookups: lookupQueries });
  const mutations = api.useMutator<
    | any
  >();
  const { isLoading, data, mainQueryKey, refetch } =
    api.useFetchSingleItem<ResponseType>({
      endpoint,
      ids: [customerId ?? ""],
      enabled: customerId !== null,
    });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isLoading || !data) {
    return <div>Loading...</div>;
  }

  const submit = async (
    payload: CustomerEntryRequest | CustomerContactRequest

  ): Promise<api.ISubmitionResponse> => {
    try {
      if (taskForm.visible && taskForm.type === "add") {
        const dest = `${api.Endpoints.Customers.main}`;
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: [`${dest}/${customerId}/entries`],
          payload: {
            ...payload,
            taskActivityId: customerId,
          },
        });
      } else if (taskForm.visible) {
        const dest = api.Endpoints.Customers.main;
        if (taskForm.type === "add") {
          return await mutations.addHandler({
            endpoint: dest,
            mainQueryKey: [`${dest}/${customerId}/entries`],
            payload: {
              ...payload,
              customerId,
            },
          });
        } else {
          return await mutations.editHandler({
            endpoint: `${dest}/${taskForm.payload.id}`,
            mainQueryKey: [`${dest}/${customerId}/entries`],
            payload: {
              ...payload,
              taskActivityId: customerId,
            },
          });
        }
      }
      else if (customerContactForm.visible) {
        if (customerContactForm.type === "addContact") {
          const dest = `${api.Endpoints.Customers.main}`;
          return await mutations.addHandler({
            endpoint: dest,
            mainQueryKey: [`${dest}/${customerId}/contacts`],
            payload: {
              ...payload,
              taskActivityId: customerId,
            },
          });
        }
        else {
          const dest = `${api.Endpoints.Customers.main}`;
          return await mutations.editHandler({
            endpoint: `${dest}/${customerContactForm.payload.id}`,
            mainQueryKey: [`${dest}/${customerId}/contacts`],
            payload: {
              ...payload,
              taskActivityId: customerId,
            },
          });
        }
      }

      else {
        const dest = "";
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: [dest],
          payload,
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as api.IErrorResult,
      };
    } finally {
      mutations.invalidateQuery(mainQueryKey);
    }
  };
  const confirmPopup = async () => {
    try {
      if (showPopup.visible) {
        const dest = `${api.Endpoints.Customers.main}`;

        switch (showPopup.type) {
          // case "inprogress": {
          //   await mutations.editHandler({
          //     endpoint: api.Endpoints.TaskDetails.inProgress(showPopup.id.toString()),
          //     mainQueryKey: [`${dest}?taskActivityId=${customerId!}`],
          //   });
          //   refetch();
          //   message.success("Successfuly completed");

          //   break;
          // }
          // case "done": {
          //   await mutations.editHandler({
          //     endpoint: api.Endpoints.TaskDetails.done(showPopup.id.toString()),
          //     mainQueryKey: [`${dest}?taskActivityId=${customerId!}`],
          //   });
          //   refetch();
          //   message.success("Successfuly completed");

          //   break;
          // }
          case "activate": {
            await mutations.addHandler({
              endpoint: api.Endpoints.Employee.revoke(customerId!),
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfuly completed");
            break;
          }
          default:
            break;
        }
      }
    } catch (error) {
      const err = error as api.IErrorResult;
      message.error(err.detail ?? err.title ?? "Error");
    } finally {
      setShowPopup({ ...showPopup, visible: false });
    }
  };
  const deleteHandler = async (id: number | string, type: MenuActions) => {
    try {
      switch (type) {
        case "delete": {
          const dest = api.Endpoints.Customers.main;
          await mutations.deleteHandler({
            endpoint: `${dest}/${id}`,
            mainQueryKey: [`${dest}?customerId=${customerId!}`],
          });
          message.success("Successfuly completed");
          break;
        }
        default:
          break;
      }
    } catch (error) {
      const err = error as api.IErrorResult;
      message.error(err.detail ?? err.title ?? "Error");
    }
  };
  const editHandler = (payload: any, type: MenuActions) => {
    switch (type) {
      case "edit":
        setTaskForm({ visible: true, payload, type: "edit" });
        break;

      default:
        break;
    }
  };
  const handlerMenuClick = (payload: any, type: MenuActions) => {

    switch (type) {
      case "add":
        setTaskForm({
          visible: true,
          type: "add",
          payload: payload
        });
        break;
      case "done":
        setShowPopup({
          id: payload.id,
          title: "Are you sure you want to complete this task?",
          visible: true,
          type: "done",
        });
        break;
      case "inprogress":
        setShowPopup({
          id: payload.id,
          title: "Are you sure you want to revoke this task?",
          visible: true,
          type: "inprogress",
        });
        break;
      case "addContact":
        setCustomerContactForm({
          visible: true,
          type: "addContact",
          payload: payload
        });
        break;

      default:
        throw new Error("Clicked On uknown menu button");
    }
  };
  return (
    <>
      <ProfileHeader
        title="Customer Profile"
        data={data.data}
        options={options.data}
      />
      <DetailsTab

        customerId={customerId}
        lookups={{
          data: options.data,
          isloading: isLoading,
        }}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        handlerMenuClick={handlerMenuClick}
        data={data.data}
      />
      <ActionsForm
        lookups={options.data}
        visible={taskForm.visible}
        title={"Customer Detail"}
        onFinish={submit}
        defaultValue={taskForm.payload as any}
        onClose={() => setTaskForm({ ...taskForm, visible: false })}
      />
      <CustomerContactForm visible={customerContactForm.visible} lookups={options.data} onFinish={submit} title={"Customer Contacts"} defaultValue={{ name: "", phoneNumber: "", email: "", address: "", sex: 0 }} onClose={() => setCustomerContactForm({ ...customerContactForm, visible: false })} />
      <PopUpActions
        closable={false}
        title={showPopup.title}
        id={showPopup.id}
        visible={showPopup.visible}
        width={400}
        visibilityChangeFunc={() =>
          setShowPopup({ ...showPopup, visible: false })
        }
        onConfirm={confirmPopup}
      />
    </>
  );
};

export default Index;
