import Result from "antd/es/result";
import Spin from "antd/es/spin";
type loadingProps = {
  msg?: string;
  margin?: string;
};
const loading = ({ msg = "Loading...", margin = "30% auto" }: loadingProps) => {
  return (
    <div style={{ margin }}>
      <Result icon={<Spin size="large" />} title={msg} />
    </div>
  );
};

export default loading;
