import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Popconfirm from "antd/es/popconfirm";
import EditButton from "@ant-design/icons/EditOutlined";
import DeleteButton from "@ant-design/icons/DeleteOutlined";
import ViewButton from "@ant-design/icons/EyeOutlined";
import tableExport from "antd-table-export";

import { ResponseType, TableProps } from "./types";
import { ICombo, ILookupKeys } from "components/api";
import { Authorize } from "components/auth";

const Table = (props: TableProps) => {
  const navigate = useNavigate();
  const { isLoading, total, pageSize, dataSource, SendRequestForData } = props;
  const columns = useCallback(
    (
      lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [
        {
          title: "Name",
          dataIndex: "name",
          order: 1,
        },
        {
          title: "Phone Number",
          dataIndex: "phoneNumber",
          order: 1,
        },
        {
          title: "City",
          dataIndex: "city",
          hideInSearch: false,
          request: async () => lookups["cities"],
          render: (_, { city }) => city.name,
          order: 1,
        },
        {
          title: "Sex",
          dataIndex: "sex",
          hideInSearch: false,
          request: async () => lookups["sex"],
          render: (_, { sex }) => sex.name,
          order: 1,
        },
        {
          title: "Date Of Birth",
          dataIndex: "dateOfBirth",
          valueType: "date",
          hideInSearch: true,
        },

        {
          title: "Passport Number",
          dataIndex: "passportNumber",
          hideInSearch: true,
        },
        {
          title: "Country Of Origin",
          dataIndex: "countryOfOrigin",
          hideInSearch: true,
        },
        {
          title: "Address",
          dataIndex: "address",
          hideInSearch: true,
        },
        {
          title: "Operations",
          width: 200,
          key: "options",
          valueType: "option",
          render: (_, record) => {
            const showActions =
              record.id !== "6123eb13-9d96-4eec-a275-e5a1c93f8852";
            return showActions ? (
              <Space size="middle">
                <Button
                  onClick={() =>
                    navigate({
                      pathname: "details",
                      search: "id=" + record.id,
                    })
                  }
                  type="default"
                  icon={<ViewButton />}
                  title="Details"
                />
                {Authorize({ claim: 'DCU' }) && (
                  <Popconfirm
                    title={`Are you sure to  delete "${record.name ?? "this"
                      }" from the system?`}
                    onConfirm={() => props.deleteHandler(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >

                    <Button
                      danger
                      type="primary"
                      icon={<DeleteButton />}
                      title="Delete"
                    />
                  </Popconfirm>
                )}
                {Authorize({ claim: 'UCU' }) && (
                  <Button
                    onClick={() => props.editHandler(record)}
                    type="primary"
                    icon={<EditButton />}
                    title="Edit"
                  />
                )}

              </Space>
            ) : null;
          },
        },

      ];
    },
    [navigate, props]
  );
  const renderedColumns = columns(props.lookups.data, props.lookups.isloading);

  const exportInstance = new tableExport(dataSource.map(v => ({
    ...v,
  })),
    renderedColumns.filter(x => x.key !== 'options').map(x => ({
      dataIndex: x.dataIndex as string,
      title: x.title! as string
    }))
  );
  const addButton = Authorize({ claim: 'CCU' }) ? <Button type="primary" onClick={props.addNewHandler}>Add New</Button> : null;
  return (
    <ProTable<ResponseType>
      columns={renderedColumns}
      loading={isLoading}
      request={async (params = {}, sort, filter) =>
        SendRequestForData({ params, sort, filter })
      }
      onReset={() => {
        let params = { current: 1, pageSize };
        let sort = {};
        let filter = {};
        SendRequestForData({ params, sort, filter, isReset: true });
      }}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        showQuickJumper: true,
        pageSize,
        total,
      }}
      search={{ labelWidth: "auto" }}
      dateFormatter="string"
      toolBarRender={() => [
        <Button type="primary" onClick={() => exportInstance.download("Employees List", "xlsx")}>
          Export
        </Button>,
        addButton

      ]}
    />
  );
};

export default Table;
