import { useState } from "react";
import { ModalForm, ProFormText, ProFormSelect, ProFormDigit, ProFormSwitch } from "@ant-design/pro-form";
import message from "antd/es/message";

import { RequestType, FormProps } from "./types";
import { ISubmitionResponse } from "components/api";
import Error from "components/ui/error";
const Form = (props: FormProps) => {
  const { state, title, visible, onVisibleChange, initialState, onFinish } =
    props;
  const [formErrors, setFormErrors] = useState<ISubmitionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };
  return (
    <ModalForm<RequestType>
      title={`${state} ${title}`}
      size={"middle"}
      width={500}
      visible={visible}
      onVisibleChange={onVisibleChange}
      autoFocusFirstInput
      request={async () => initialState}
      name="galawo-model-form"
      modalProps={{
        onCancel: () => onVisibleChange(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        var result = await onFinish(values);
        if (result.isSuccess) {
          message.success("Successfuly completed",);
        }
        setFormErrors(result);
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >

      <ProFormText
        name="name"
        label="Name"
        placeholder="Enter Name"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="xl"
      />
      {/* <ProFormSwitch
        name="active"
        label="active"
        placeholder="Enter active"
        rules={[{ required: true }]}
        width="xl"
      /> */}
      <ProFormText
        name="description"
        label="Description"
        placeholder="Enter a Description"
        rules={[{ max: 250 }]}
        width="xl"
      />
      <ProFormSelect
        name="countryId"
        label="Country"
        placeholder="Select a Country"
        rules={[{ required: true }]}
        request={async () => props.lookups.data["countries"] ?? []}
        debounceTime={0}
        showSearch={true}
        width="xl"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </ModalForm>
  );
};

export default Form;
