import { useContext } from "react";
import { pick, keys, values } from "lodash";
import { KnownClaims, KnownValues, AuthContext } from ".";

export type AuthorizeProps = {
  claim: KnownClaims;
};

type isAuthorizedProps = AuthorizeProps & {
  yes: JSX.Element;
  no: JSX.Element;
};

export const Authorize = ({ claim }: AuthorizeProps) => {
  const { user } = useContext(AuthContext);

  var authClaims = pick(user, ["role"]);
  if (!authClaims) {
    return false;
  }

  // if (!authClaims.role.includes(claim)) {
  //   return false;
  // }

  if (!authClaims.role) {
    return false;

  }

  if (!authClaims.role.includes(claim)) {
    return false;
  }

  return true;
};

export const isAuthorized = ({ claim, yes, no }: isAuthorizedProps) =>
  Authorize({ claim }) ? yes : no;
