import Tabs from "antd/es/tabs";
import { ICombo } from "components/api";
import { MenuActions } from ".";

import { ResponseType } from "pages/employees";
import { JobType } from ".";
import PersonalInfo from "./personal-info";
import RolesTable from "./roles-table";
const { TabPane } = Tabs;

export type tapPageProps = {
  employeeId: string | null;
  lookups: { data: Record<string, ICombo[]>; isloading: boolean };
  data: ResponseType;
  deleteHandler: (id: number | string, type: MenuActions) => void;
  editHandler: (payload: any, type: MenuActions) => void;
  job: {
    data: JobType | undefined;
    isError: boolean;
  };
};

const ProfileTabs = (props: tapPageProps) => {
  const tableProps = {
    employeeId: props.employeeId,
    lookups: props.lookups,
    deleteHandler: props.deleteHandler,
    editHandler: props.editHandler,
  };
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Info" key="1">
        <PersonalInfo
          data={props.data}
          job={props.job.data}
          isError={props.job.isError}
        />
      </TabPane>
      <TabPane tab="Roles" key="11">
        <RolesTable {...tableProps} />
      </TabPane>
      {/* <TabPane tab="Emergency Contacts" key="2">
        <EmContactsTable {...tableProps} />
      </TabPane>
      <TabPane tab="Leaves" key="3">
        <LeavesTable {...tableProps} />
      </TabPane>
      <TabPane tab="Allowances" key="4">
        <AllowanceTable {...tableProps} />
      </TabPane>
      <TabPane tab="Overtimes" key="5">
        <OvertimeTable {...tableProps} />
      </TabPane>
      <TabPane tab="Deductions" key="6">
        <DeductionTable {...tableProps} />
      </TabPane>
      <TabPane tab="History (Positions)" key="10">
        Under development
      </TabPane>
      <TabPane tab="Roles" key="11">
        <RolesTable {...tableProps} />
      </TabPane> */}
    </Tabs>
  );
};

export default ProfileTabs;
