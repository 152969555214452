import { loginFormType } from "components/api/types";
import { useEffect, useState } from "react";
import auth from "./service";
const authService = new auth();
const useAuthService = () => {
  const [state, setState] = useState(authService.state);
  const [loginProgress, setLoginProgress] = useState<boolean>(false);
  const loginFC = async (payload: loginFormType) => {
    setLoginProgress(true);
    const result = await authService.login(payload);
    setLoginProgress(false);
    return result;
  };


  useEffect(() => {
    console.log("starting up");
    authService.startUp(setState);
    return () => {
      console.log("shutting down");
      authService.unMount(setState);
    };
  }, []);

  return {
    state,
    loginFC,
    logoutFC: authService.logout,
    loginProgress,
  };
};

export default useAuthService;
