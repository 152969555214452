import { Routes, Route, BrowserRouter as Router, Outlet } from "react-router-dom";
import * as Pages from "pages";
import { Layout } from "components/layout";
import PrivateRoute from "./utils";
type ComponentType = {
  children?: React.ReactNode;
}

const AppRoutes = () => {

  const SidebarLayout = ({ children }: ComponentType) => (
    <Layout>
      {/* <PrivateRoute element={children} /> */}
      <Outlet />
    </Layout>
  );
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Pages.ErrorPages status="404" />} />
        <Route path="/" element={<SidebarLayout />} >

          <Route path="/" element={<Pages.HomePage />} />
          <Route path="/settings/countries" element={<Pages.CountryPage />} />
          <Route path="/settings/cities" element={<Pages.CityPage />} />
          <Route path="/settings/conditions" element={<Pages.ConditionPage />} />
          <Route path="/settings/document-types" element={<Pages.DocumentTypePage />} />
          <Route path="/settings/users" element={<Pages.EmployeePage />} />
          <Route path="/settings/roles" element={<Pages.RolePage />} />
          <Route
            path="/settings/users/details"
            element={<Pages.EmployeeDetailsPage />}
          />
          <Route path="/customers/" element={<Pages.CustomerPage />} />
          <Route path="/results/" element={<Pages.ResultPage />} />
          <Route path="/customers/details" element={<Pages.CustomerDetailPage />} />


          <Route path="/hr" element={<Pages.WelcomePage page="HR" />} />
          <Route path="/finance" element={<Pages.WelcomePage page="Finance" />} />
          <Route
            path="/procurement"
            element={<Pages.WelcomePage page="Procurement" />}
          />
          <Route
            path="/projects"
            element={<Pages.WelcomePage page="Projects" />}
          />
        </Route>

      </Routes>
    </Router>
  );
};
export default AppRoutes;
