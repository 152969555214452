import { useState } from "react";
import { ModalForm, ProFormSelect } from "@ant-design/pro-form";
import message from "antd/es/message";

import Error from "components/ui/error";
import { ICombo, ILookupKeys, ISubmitionResponse } from "components/api";

export type RoleRequestType = {
  groupId: string;
};

type FormProps = {
  visible: boolean;
  onClose: (visible: boolean) => void;
  lookups: Record<ILookupKeys, ICombo[]>;
  onFinish: (values: RoleRequestType) => Promise<ISubmitionResponse>;
  title: string;
  defaultValue: RoleRequestType;
};

const RolesForm = (props: FormProps) => {
  const { visible, onClose, onFinish } = props;
  const [formErrors, setFormErrors] = useState<ISubmitionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  return (
    <ModalForm<RoleRequestType>
      title={props.title}
      size={"middle"}
      width={400}
      open={visible}
      autoFocusFirstInput
      request={async () => props.defaultValue}
      name="galawo-model-form"
      modalProps={{
        onCancel: () => onClose(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        var result = await onFinish(values);
        setFormErrors(result);
        if (result.isSuccess) {
          message.success("Successfully completed");

          onClose(false);
        }
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormSelect
        name="groupId"
        label="Role"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.roles ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select Role"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </ModalForm>
  );
};

export default RolesForm;
