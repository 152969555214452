import { ParamsType } from "@ant-design/pro-provider";
import { SortOrder } from "antd/lib/table/interface";

import { toAddIdSuffix } from "assets";
import { ILookup } from "components/api";
import { IChangeRequestProps } from "pages/common";
export function instanceOfILookup(object: any): object is ILookup {
  return object.discriminator === "ILookup";
}

type paginateEndpointProps = {
  endpoint: string;
  includeEndpoint: boolean;
  requestParams: IChangeRequestProps;
  dependent?: { key: string | undefined; value: any };
  outsiderDependent: boolean;
};
const isValid = (value: any) =>
  value !== null && value !== undefined && value !== "";

const addSuffixToKeys = (key: string) =>
  !toAddIdSuffix.includes(key) ? key : key + "Id";

const createSearchParams = (obj: ParamsType) => {
  const search = Object.keys(obj).map((key) => {
    const searchKey = addSuffixToKeys(key);
    return isValid(obj[key]) ? `${searchKey}=${obj[key]}` : null;
  });
  return search.filter(isValid).join("&");
};

const createSortParams = (obj: Record<string, SortOrder> | undefined) => {
  if (obj === undefined) {
    return;
  }
  return Object.keys(obj).map((key) => {
    return obj[key] ? `&sort=${key}_${obj[key]}` : null;
  });
};

export const paginateEndpoint = ({
  endpoint,
  requestParams,
  includeEndpoint,
  dependent,
  outsiderDependent,
}: paginateEndpointProps) => {
  const { params, sort } = requestParams;
  const { current, keyword, pageSize, ...rest } = params;
  if (outsiderDependent && dependent && dependent.key) {
    rest[dependent.key] = dependent.value;
  } else if (!outsiderDependent && dependent && dependent.key) {
    // to persist location with url search
    if (!rest[dependent.key]) {
      rest[dependent.key] = dependent.value;
    }
  }
  const makeSearch = createSearchParams(rest);
  const sortParam = createSortParams(sort);

  let searchParams = makeSearch ? "&" + makeSearch : "";
  searchParams += sortParam ? "" + sortParam : "";

  return includeEndpoint
    ? `/${endpoint}?size=${pageSize}&index=${current}${searchParams}`
    : `?size=${pageSize}&index=${current}${searchParams}`;
};
