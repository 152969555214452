import Tabs from "antd/es/tabs";
import { ICombo } from "components/api";


import { ResponseType } from "pages/tasks";
import TaskDetails from "pages/tasks/details/customer-entries";
import { MenuActions } from ".";
import TaskComments from "./customer-contacts";
import CustomerEntries from "pages/tasks/details/customer-entries";
import CustomerContacts from "./customer-contacts";
const { TabPane } = Tabs;

export type tapPageProps = {
  customerId: string | null;
  lookups: { data: Record<string, ICombo[]>; isloading: boolean };
  data: ResponseType;
  deleteHandler: (id: number | string, type: MenuActions) => void;
  editHandler: (payload: any, type: MenuActions) => void;
  handlerMenuClick: (payload: any, type: MenuActions) => void;
};

const ProfileTabs = (props: tapPageProps) => {
  const tableProps = {
    customerId: props.customerId,
    lookups: props.lookups,
    deleteHandler: props.deleteHandler,
    editHandler: props.editHandler,
    handlerMenuClick: props.handlerMenuClick,

  };
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Customer Entries" key="1">
        <CustomerEntries {...tableProps} />
      </TabPane>
      <TabPane tab="Customer Contacts" key="2">
        <CustomerContacts {...tableProps} />
      </TabPane>
      {/* <TabPane tab="Emergency Contacts" key="2">
        <EmContactsTable {...tableProps} />
      </TabPane>
      <TabPane tab="Leaves" key="3">
        <LeavesTable {...tableProps} />
      </TabPane>
      <TabPane tab="Allowances" key="4">
        <AllowanceTable {...tableProps} />
      </TabPane>
      <TabPane tab="Overtimes" key="5">
        <OvertimeTable {...tableProps} />
      </TabPane>
      <TabPane tab="Deductions" key="6">
        <DeductionTable {...tableProps} />
      </TabPane>
      <TabPane tab="History (Positions)" key="10">
        Under development
      </TabPane>
      <TabPane tab="Roles" key="11">
        <RolesTable {...tableProps} />
      </TabPane> */}
    </Tabs>
  );
};

export default ProfileTabs;
