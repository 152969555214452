import { useState } from "react";
import {
  ModalForm,
  ProFormText,
  ProFormSelect,
  ProFormDateRangePicker,
} from "@ant-design/pro-form";
import message from "antd/es/message";

import { RequestType, FormProps } from "./types";
import { ISubmitionResponse } from "components/api";
import { dateTimeFormats } from "assets";
import Error from "components/ui/error";
const Form = (props: FormProps) => {
  const { state, title, visible, onVisibleChange, initialState, onFinish } =
    props;
  const [formErrors, setFormErrors] = useState<ISubmitionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };
  return (
    <ModalForm<RequestType>
      title={`${state} ${title}`}
      size={"middle"}
      width={500}
      visible={visible}
      onVisibleChange={onVisibleChange}
      autoFocusFirstInput
      request={async () => initialState}
      name="galawo-model-form"
      modalProps={{
        onCancel: () => onVisibleChange(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        var result = await onFinish({
          ...values,
        });
        if (result.isSuccess) {
          message.success("Successfuly completed");
        }
        setFormErrors(result);
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >

      <ProFormText
        name="fullName"
        label="Full Name"
        placeholder="Enter Full Name"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="xl"
      />
      <ProFormText
        name="email"
        label="Email"
        placeholder="Enter Email"
        rules={[{ required: true, min: 2, max: 50, type: "email" }]}
        width="xl"
      />
      <ProFormText
        name="phone"
        label="Phone"
        placeholder="Enter Phone"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="xl"
      />
      <ProFormSelect
        name="companyId"
        label="Company"
        rules={[{ required: true }]}
        width="xl"
        request={async () => props.lookups.data["companies"] ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select Company"
      />
      <ProFormSelect
        name="sex"
        label="Gender"
        rules={[{ required: true }]}
        width="xl"
        request={async () => props.lookups.data["sex"] ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select Sex"
      />

      <ProFormText
        name="description"
        label="Description"
        placeholder="Enter a Description"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="xl"
      />

      <Error error={formErrors.error} isError={formErrors.isError} />
    </ModalForm>
  );
};

export default Form;
