import { useMutation, useQueryClient } from "react-query";
import { useAxios, ISubmitionResponse } from ".";

type mutationProps<D> = {
  data?: D | D[];
  endpoint: string;
  type: "add" | "edit" | "delete" | "list";
  mainQueryKey: (string | null)[];
};

type actionType<D> = {
  payload?: D | D[];
  endpoint: string;
  mainQueryKey: any;
};

function Mutator<D>() {
  const queryClient = useQueryClient();
  const axios = useAxios();

  const { mutateAsync } = useMutation(
    async (props: mutationProps<D>) => {
      const { data, endpoint } = props;
      switch (props.type) {
        case "add":
          return await axios.post<D>(endpoint, data);
        case "list":
          return await axios.post<D>(endpoint, { list: data });
        case "edit":
          return await axios.put<D>(endpoint, data);
        case "delete":
          return await axios.delete<any>(endpoint);
      }
    },
    {
      onSuccess: (_, props) => {
        queryClient.invalidateQueries(props.mainQueryKey);
      },
    }
  );

  const addHandler = async ({
    endpoint,
    mainQueryKey,
    payload,
  }: actionType<D>): Promise<ISubmitionResponse> => {
    const { data, status } = await mutateAsync({
      endpoint,
      mainQueryKey,
      data: payload,
      type: "add",
    });
    if (status === 200 || status === 201) {
      return { error: data, isError: false, isSuccess: true };
    }
    return { error: data, isError: true, isSuccess: false };
  };

  const addListHandler = async ({
    endpoint,
    mainQueryKey,
    payload,
  }: actionType<D>): Promise<ISubmitionResponse> => {
    const { data, status } = await mutateAsync({
      endpoint,
      mainQueryKey,
      data: payload,
      type: "list",
    });
    if (status === 200 || status === 201) {
      return { error: data, isError: false, isSuccess: true };
    }
    return { error: data, isError: true, isSuccess: false };
  };

  const editHandler = async ({
    endpoint,
    mainQueryKey,
    payload,
  }: actionType<D>): Promise<ISubmitionResponse> => {
    const { data, status } = await mutateAsync({
      endpoint,
      data: payload,
      mainQueryKey,
      type: "edit",
    });
    if (status === 200 || status === 201) {
      return { error: data, isError: false, isSuccess: true };
    }
    return { error: data, isError: true, isSuccess: false };
  };

  const deleteHandler = async ({
    mainQueryKey,
    endpoint,
  }: actionType<D>): Promise<ISubmitionResponse> => {
    const { data, status } = await mutateAsync({
      type: "delete",
      mainQueryKey,
      endpoint,
    });
    if (status === 200 || status === 201) {
      return { error: data, isError: false, isSuccess: true };
    }
    return { error: data, isError: true, isSuccess: false };
  };
  const invalidateQuery = (key: any) => queryClient.invalidateQueries(key);
  return {
    addHandler,
    editHandler,
    deleteHandler,
    addListHandler,
    invalidateQuery,
  };
}
export default Mutator;
