import { useContext } from "react";
import axios from "axios";
import { baseUrl } from "assets/";
import { normalizeErrors } from "utils";
import { AuthContext } from "components/auth";
const useAxios = () => {
  const { accessToken } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  axiosInstance.interceptors.request.use(
    async (req) => {
      return req;
    },
    (error) => {
      const transformedError = normalizeErrors(error);
      return Promise.reject(transformedError);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      const transformedError = normalizeErrors(error);
      return Promise.reject(transformedError);
    }
  );

  return axiosInstance;
};

export default useAxios;
