import { useState } from "react";
import { ModalForm, ProFormSelect } from "@ant-design/pro-form";
import message from "antd/es/message";

import Error from "components/ui/error";
import { Endpoints, ICombo, ILookup, ILookupKeys, ISubmitionResponse, useMutator, useQuery } from "components/api";
import Table from "./permission-table";
import { IChangeRequestProps } from "pages/common";
import { paginateEndpoint } from "utils";
import { useLocation, useNavigate } from "react-router-dom";


;

export type PermissionRequestType = {
  permissionId: string;
  groupId: string;
};

type FormProps = {
  visible: boolean;
  onClose: (visible: boolean) => void;
  lookups: Record<ILookupKeys, ICombo[]>;
  onFinish: (values: PermissionRequestType) => Promise<ISubmitionResponse>;
  title: string;
  defaultValue: PermissionRequestType;
};

const PermissionForm = (props: FormProps) => {
  const { visible, onClose, onFinish } = props;
  const [formErrors, setFormErrors] = useState<ISubmitionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const { data, isLoading, refetch, error, isError, mainQueryKey } = useQuery<
    ILookup[]
  >({ endpoint: Endpoints.Roles.permissions(props.defaultValue.groupId) });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };
  const mutations = useMutator<PermissionRequestType>();
  const [locationData, setLocationData] = useState<string>("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const SendRequestForData = ({
    params,
    sort,
    filter,
    isReset,
  }: IChangeRequestProps) => {
    const newLogaction = paginateEndpoint({
      endpoint: Endpoints.Roles.permissions(props.defaultValue.groupId),
      includeEndpoint: false,
      requestParams: { params, sort, filter },
      dependent: undefined,
      outsiderDependent: false,
    });
    console.log("current", locationData);
    console.log("next", newLogaction);

    if (locationData !== newLogaction) {
      setLocationData(newLogaction);
      navigate({
        pathname: pathname,
        search: newLogaction,
      });
    }

    return data?.data ?? [];
  };


  const deleteOnClickHandler = async (id: number | string) => {
    return await mutations.deleteHandler({
      mainQueryKey: mainQueryKey,
      endpoint: Endpoints.Roles.deletePermission(props.defaultValue.groupId, id?.toString()),
    });
  };

  return (
    <ModalForm<PermissionRequestType>
      title={props.title}
      size={"middle"}
      width={"50%"}
      open={visible}

      autoFocusFirstInput
      request={async () => props.defaultValue}
      name="galawo-model-form"
      modalProps={{
        onCancel: () => onClose(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        var result = await onFinish(values);
        setFormErrors(result);
        if (result.isSuccess) {
          message.success("Successfully completed");
          refetch();
          //onClose(false);
        }
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <Table
        dataSource={data?.data ?? []}
        deleteHandler={deleteOnClickHandler}
        SendRequestForData={SendRequestForData}
        isLoading={isLoading}
        pageSize={data?.size ?? 10}
        total={data?.count ?? 0}
        lookups={{
          data: {},
          isloading: false,
        }}
      />
      <ProFormSelect
        name="permissionId"
        label="Permission"
        bordered={true}
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.permissions ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select Permission"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />

    </ModalForm>
  );
};

export default PermissionForm;
