import { useQuery } from "react-query";
import { staleTime } from "assets/";
import { IErrorResult, IResponse, useAxios } from "components/api";

type ProfileBLProps = {
  ids: string[] | null;
  endpoint: string;
  enabled: boolean;
};
function QueryBy<T>({ endpoint, ids, enabled }: ProfileBLProps) {
  const axios = useAxios();
  const transformedEndpoint = ids ? `${endpoint}/${ids.join("/")}` : endpoint;
  const mainQueryKey = [endpoint, ids];

  const { isLoading, isError, data, error, isFetching, refetch } = useQuery<
    IResponse<T>,
    IErrorResult
  >(
    mainQueryKey,
    async () => {
      const { data } = await axios.get(transformedEndpoint);
      return data;
    },
    {
      keepPreviousData: true,
      enabled: enabled,
      retry: false,
      staleTime: staleTime,
    }
  );

  return {
    isLoading,
    isError,
    data,
    error,
    isFetching,
    refetch,
    mainQueryKey,
  };
}
export default QueryBy;
