import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Space from "antd/lib/space";
import Popconfirm from "antd/es/popconfirm";
import Button from "antd/es/button";
import DeleteIcon from "@ant-design/icons/DeleteOutlined";
import EditIcon from "@ant-design/icons/EditOutlined";
import {
    ICombo,
    ILookupKeys,
    useFetchSingleItem,
    Endpoints,
    ILookup,
} from "components/api";

import { CustomerContactRequest } from "./customer-contact-form";
import { MenuActions } from "pages/tasks/details";
import { Authorize } from "components/auth";

type TableProps = {
    lookups: {
        data: Record<string, ICombo[]>;
        isloading: boolean;
    };
    customerId: string | null;
    deleteHandler: (id: number, type: MenuActions) => void;
    editHandler: (payload: CustomerContactRequest, type: MenuActions) => void;
    handlerMenuClick: (payload: CustomerContactRequest, type: MenuActions) => void;
};
type ResponseType = {
    id: number;
    name: string;
    sex: ILookup;
    email: string;
    phoneNumber: string;
    address: string;
    createdBy: string;
    createdOn: string;
};
const CustomerContacts = (props: TableProps) => {
    const endpoint = Endpoints.Customers.contacts(props.customerId!);
    const { data, isError } = useFetchSingleItem<ResponseType[]>({
        endpoint,
        enabled: true,
        ids: null,
    });
    const columns = useCallback(
        (
            lookups: Record<ILookupKeys, ICombo[]>,
            isLoading: boolean
        ): ProColumns<ResponseType>[] => {
            if (isLoading) {
                return [];
            }
            return [
                {
                    title: "Name",
                    dataIndex: "name",
                },
                {
                    title: "Phone Number",
                    dataIndex: "phoneNumber",
                },
                {
                    title: "Email",
                    dataIndex: "email",
                },
                {
                    title: "Sex",
                    dataIndex: "sex",
                    render: (_, record) => record.sex.name
                },
                {
                    title: "Created By",
                    dataIndex: "createdBy",

                },
                {
                    title: "Created On",
                    dataIndex: "createdOn",
                    valueType: "date",
                },
                {
                    title: "Operations",
                    width: 200,
                    key: "options",
                    valueType: "option",
                    render: (_, record) => {
                        return (
                            <Space size="middle">
                                {Authorize({ claim: 'DCU' }) &&
                                    <Popconfirm
                                        title={`Are you sure to delete the selected contact from the system?`}
                                        onConfirm={() => props.deleteHandler(record.id, "delete")}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            danger
                                            type="primary"
                                            icon={<DeleteIcon />}
                                            title="Delete"
                                        />
                                    </Popconfirm>
                                }
                                {/* <Button
                                    onClick={() => {
                                        const { ...details } = record;
                                        props.editHandler(
                                            {
                                                ...details,
                                                customerId: parseInt(props.customerId!),
                ,
                                            },
                                        "edit"
                                        );
                                    }}
                                type="primary"
                                icon={<EditIcon />}
                                title="Edit"
                                /> */}


                            </Space>
                        );
                    },
                },
            ];
        },
        [props]
    );
    if (isError || !data) {
        return <div>Error</div>;
    }
    const addCommentButton = Authorize({ claim: 'CCU' }) ? <Button
        onClick={() => {
            props.handlerMenuClick(
                {
                    name: "",
                    email: "",
                    phoneNumber: "",
                    address: "",
                    sex: 0,
                },
                "addContact"
            );
        }}
        type="primary"
        icon={<EditIcon />}
        title="Add Contact"

    >Add Contact</Button> : null;
    return (
        <ProTable<ResponseType>
            headerTitle={addCommentButton}
            columns={columns(props.lookups.data, props.lookups.isloading)}
            loading={false}
            request={async () => data?.data}
            dataSource={data?.data ?? []}
            rowKey="id"
            search={false}
            dateFormatter="string"
            pagination={false}
        />
    );
};

export default CustomerContacts;
