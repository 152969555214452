import { useNavigate, useLocation } from "react-router-dom";

import {
  Endpoints,
  ISubmitionResponse,
  useQuery,
  useLookups,
  useMutator,
  IErrorResult,
} from "components/api";
import Header from "components/ui/header";
import { IChangeRequestProps } from "pages/common";
import { useState } from "react";
import { paginateEndpoint } from "utils";
import Form from "./form";
import Table from "./table";
import { RequestType, ResponseType, FormStateProps } from "./types";

const endpoint = Endpoints.DocumentTypes.main;
const initialState: RequestType = {
  id: "",
  name: "",
  description: ""
};
const DocumentType = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [formState, setFormState] = useState<FormStateProps>({
    visible: false,
    addForm: true,
    initialState,
  });

  const [locationData, setLocationData] = useState<string>("");

  const { data, isLoading, refetch, error, isError, mainQueryKey } = useQuery<
    ResponseType[]
  >({ endpoint });

  const mutations = useMutator<RequestType | ResponseType>();

  const submit = async (payload: RequestType): Promise<ISubmitionResponse> => {
    try {
      if (formState.addForm) {
        return await mutations.addHandler({
          endpoint,
          mainQueryKey,
          payload,
        });
      } else {
        return await mutations.editHandler({
          endpoint: `${endpoint}/${formState.initialState.id}`,
          mainQueryKey: mainQueryKey,
          payload,
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as IErrorResult,
      };
    }
  };

  const formVisibleChange = (
    visible: boolean,
    init = initialState,
    addForm = true
  ) => {
    if (formState.visible === visible) {
      return;
    }
    setFormState({
      visible,
      addForm,
      initialState: init,
    });
  };

  const editOnClickHandler = ({ description, name, ...rest }: ResponseType) => {
    console.log('rest', rest);

    return formVisibleChange(
      true,
      {
        ...rest,
        description,
        name,

      },
      false
    );
  };
  const deleteOnClickHandler = async (id: number | string | undefined) => {
    return await mutations.deleteHandler({
      mainQueryKey: mainQueryKey,
      endpoint: endpoint + "/" + id,
    });
  };

  const SendRequestForData = ({
    params,
    sort,
    filter,
    isReset,
  }: IChangeRequestProps) => {
    const newLogaction = paginateEndpoint({
      endpoint,
      includeEndpoint: false,
      requestParams: { params, sort, filter },
      dependent: undefined,
      outsiderDependent: false,
    });
    console.log("current", locationData);
    console.log("next", newLogaction);

    if (locationData !== newLogaction) {
      setLocationData(newLogaction);
      navigate({
        pathname: pathname,
        search: newLogaction,
      });
    }

    return data?.data ?? [];
  };

  // if (error || isError) {
  //   return <div>Error</div>;
  // }

  return (
    <>
      <Header
        title="Document Types"
        error={error?.detail ?? ""}
        isError={isError}
        isLoading={isLoading}
        refetch={refetch}
      />
      <Table
        SendRequestForData={SendRequestForData}
        addNewHandler={() => formVisibleChange(true)}
        dataSource={data?.data ?? []}
        deleteHandler={deleteOnClickHandler}
        editHandler={editOnClickHandler}
        isLoading={isLoading}
        pageSize={data?.size ?? 10}
        total={data?.count ?? 0}
        lookups={{
          data: {},
          isloading: false,
        }}
      />
      <Form
        title="Document Types"
        initialState={formState.initialState}
        lookups={{
          data: {},
          isloading: false,
        }}
        onFinish={submit}
        onVisibleChange={(visible) => formVisibleChange(visible)}
        state={formState.addForm ? "Add New" : "Edit"}
        visible={formState.visible}
      />
    </>
  );
};

export default DocumentType;
