import { SmileOutlined } from "@ant-design/icons";
import { SettingOutlined } from "@ant-design/icons";
import menu from "components/routes/menu";
const defaultProps = (group: string | undefined) => {
  const routes = menu(group);
  console.log('routes', routes);
  return {
    route: {
      path: "/",
      routes: [
        {
          path: "/",
          name: "Home",

          icon: <SmileOutlined />,
        },

        ...routes,
      ],
    },
    location: {
      pathname: "/",
    },
  };
};

export default defaultProps;
