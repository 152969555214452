import { useNavigate } from "react-router-dom";
import Result from "antd/es/result";
import Button from "antd/lib/button";

type loadingProps = {
  status: "404" | "403" | "500" | "error";
};
const ErrorPage = ({ status }: loadingProps) => {
  const navigate = useNavigate();
  let msg = "";
  switch (status) {
    case "404":
      msg = "Sorry, the page you visited does not exist.";
      break;
    case "403":
      msg = "Sorry, you are not authorized to access this page.";
      break;
    case "500":
      msg = "Sorry, something went wrong.";
      break;
    default:
      msg = "Oops! unknown error accrued";
      break;
  }
  return (
    <Result
      status={status}
      title={msg}
      subTitle=""
      extra={
        <Button onClick={() => navigate("/")} type="primary">
          Back Home
        </Button>
      }
    />
  );
};

export default ErrorPage;
