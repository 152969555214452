import SettingOutLined from "@ant-design/icons/SettingOutlined";
import TeamOutlined from "@ant-design/icons/TeamOutlined";
import ScheduleOutlined from "@ant-design/icons/ScheduleOutlined";
import HistoryOutlined from "@ant-design/icons/HistoryOutlined";

import { Authorize } from "components/auth";
import * as types from "./types";
import data from "./data.json";

const appMenuList: types.IAppMenuItem[] = data as types.IAppMenuItem[];

const listMenu = (group: string | undefined): types.IAppMenuList[] => {

  const settingsMenu: types.IAppMenuList = {
    name: "Settings",
    path: "/settings",
    icon: <SettingOutLined />,
    routes: [
      ...appMenuList.filter(
        (x) => Authorize(x.access) && x.group === "Settings"
      ),
    ],
  };

  const usersMenu: types.IAppMenuList = {
    name: "Users",
    path: "/users",
    icon: <TeamOutlined />,
    routes: [
      ...appMenuList.filter(
        (x) => {
          return Authorize(x.access) && x.group === "Users"
        }
      ),
    ],
  };



  const customerMenu: types.IAppMenuList = {
    name: "Customers",
    path: "/customers",
    icon: <TeamOutlined />,
    routes: [
      ...appMenuList.filter((x) => Authorize(x.access) && x.group === "Customers"),
    ],
  };

  const resultMenu: types.IAppMenuList = {
    name: "Results",
    path: "/results",
    icon: <ScheduleOutlined />,
    routes: [
      ...appMenuList.filter((x) => Authorize(x.access) && x.group === "Results"),
    ],
  };



  // const userMenu: types.IAppMenuList = {
  //   name: "Users",
  //   path: "/users",
  //   icon: <ScheduleOutlined />,
  //   routes: [
  //     ...appMenuList.filter(
  //       (x) => Authorize(x.access) && x.group === "Users"
  //     ),
  //   ],
  // };

  // const projectsMenu: types.IAppMenuList = {
  //   name: "Project Management",
  //   path: "/pm",
  //   icon: <HistoryOutlined />,
  //   routes: [
  //     ...appMenuList.filter(
  //       (x) => Authorize(x.access) && x.group === "Projects"
  //     ),
  //   ],
  // };

  let list: types.IAppMenuList[] = [];
  // if (group === undefined) {
  //   return [];
  // }




  if (customerMenu.routes?.length && customerMenu.routes.length > 0) {
    list.push(customerMenu);
  }

  if (resultMenu.routes?.length && resultMenu.routes.length > 0) {
    list.push(resultMenu);
  }

  if (settingsMenu.routes?.length && settingsMenu.routes.length > 0) {
    list.push(settingsMenu);
  }
  if (usersMenu.routes?.length && usersMenu.routes.length > 0) {
    list.push(usersMenu);
  }
  // if (projectsMenu.routes?.length && projectsMenu.routes.length > 0) {
  //   list.push(projectsMenu);
  // }

  return list;
};

export default listMenu;
