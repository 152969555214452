import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Space from "antd/lib/space";
import Popconfirm from "antd/es/popconfirm";
import Button from "antd/es/button";
import DeleteIcon from "@ant-design/icons/DeleteOutlined";
import EditIcon from "@ant-design/icons/EditOutlined";
import {
    ICombo,
    ILookupKeys,
    useFetchSingleItem,
    Endpoints,
    ILookup,
    useLookups,
} from "components/api";

import { CustomerEntryRequest } from "./customer-entry-form";
import { MenuActions } from "pages/tasks/details";
import { Authorize } from "components/auth";

type TableProps = {
    lookups: {
        data: Record<string, ICombo[]>;
        isloading: boolean;
    };
    customerId: string | null;
    deleteHandler: (id: number, type: MenuActions) => void;
    editHandler: (payload: CustomerEntryRequest, type: MenuActions) => void;
    handlerMenuClick: (payload: CustomerEntryRequest, type: MenuActions) => void;
};
type ResponseType = {
    id: number;
    dateOfEntry: string;
    customer: ILookup;
    dateOfDeparture: Date;
    duration: number;
    visaNumber: string;
    purposeOfVisit: string;
};
const CustomerEntries = (props: TableProps) => {
    const endpoint = Endpoints.Customers.entries(props.customerId!);
    const { data, isError } = useFetchSingleItem<ResponseType[]>({
        endpoint,
        enabled: true,
        ids: null,
    });

    const columns = useCallback(
        (
            lookups: Record<ILookupKeys, ICombo[]>,
            isLoading: boolean
        ): ProColumns<ResponseType>[] => {
            if (isLoading) {
                return [];
            }
            return [
                {
                    title: "Date Of Entry",
                    dataIndex: "dateOfEntry",
                },
                {
                    title: "Date Of Departure",
                    dataIndex: "dateOfDeparture",
                },
                {
                    title: "Duration",
                    dataIndex: "duration",
                },
                {
                    title: "Visa Number",
                    dataIndex: "visaNumber",
                },
                {
                    title: "Purpose Of Visit",
                    dataIndex: "purposeOfVisit",
                },
                {
                    title: "Operations",
                    width: 200,
                    key: "options",
                    valueType: "option",
                    render: (_, record) => {
                        return (
                            <Space size="middle">
                                {Authorize({
                                    claim: "DCU",
                                }) && (
                                        <Popconfirm
                                            title={`Are you sure to delete the selected task from the system?`}
                                            onConfirm={() => props.deleteHandler(record.id, "delete")}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                danger
                                                type="primary"
                                                icon={<DeleteIcon />}
                                                title="Delete"
                                            />
                                        </Popconfirm>
                                    )}

                                {Authorize({ claim: 'UCU' }) && <Button
                                    onClick={() => {
                                        const { ...details } = record;
                                        props.editHandler(
                                            {
                                                dateOfEntry: new Date(record.dateOfEntry),
                                                dateOfDeparture: new Date(record.dateOfDeparture),
                                                visaNumber: record.visaNumber,
                                                purposeOfVisit: record.purposeOfVisit,

                                            },
                                            "edit"
                                        );
                                    }}
                                    type="primary"
                                    icon={<EditIcon />}
                                    title="Edit"
                                />
                                }


                            </Space>
                        );
                    },
                },
            ];
        },
        [props]
    );
    if (isError || !data) {
        return <div>Error</div>;
    }
    const addButton = Authorize({ claim: 'CCU' }) ? <Button
        onClick={() => {
            props.handlerMenuClick(
                {
                    dateOfEntry: new Date(),
                    dateOfDeparture: new Date(),
                    visaNumber: "",
                    purposeOfVisit: "",
                },
                "add"
            );
        }}
        type="primary"
        icon={<EditIcon />}
        title="Add Entry"

    >Add Entry</Button> : null;

    return (
        <ProTable<ResponseType>
            headerTitle={addButton}
            columns={columns(props.lookups.data, props.lookups.isloading)}
            loading={false}
            request={async () => data?.data}
            dataSource={data?.data ?? []}
            rowKey="id"
            search={false}
            dateFormatter="string"
            pagination={false}
        />
    );
};

export default CustomerEntries;
