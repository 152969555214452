import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Popconfirm from "antd/es/popconfirm";

import { PermissionTableProps } from "./types";
import { ICombo, ILookup, ILookupKeys } from "components/api";
import tableExport from "antd-table-export";

const Table = (props: PermissionTableProps) => {
  const { isLoading, total, pageSize, dataSource, SendRequestForData } = props;
  const columns = useCallback(
    (
      lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ILookup>[] => {
      if (isLoading) {
        return [];
      }
      return [
        {
          title: "Name",
          dataIndex: "name",
          request: async () => lookups["permissions"],
        },
        {
          title: "Operations",
          width: 200,
          key: "options",
          valueType: "option",
          render: (_, record) => {
            return (
              <Space size="middle">

                <Popconfirm
                  title={`Are you sure to  delete "${record.name ?? "this"
                    }" from the system?`}
                  onConfirm={() => props.deleteHandler(record.id.toString())}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger type="primary">
                    Delete
                  </Button>
                </Popconfirm>
              </Space>
            );
          },
        },
      ];
    },
    [props]
  );
  const renderedColumns = columns(props.lookups.data, props.lookups.isloading);

  const exportInstance = new tableExport(dataSource.map(v => ({
    ...v,
  })),
    renderedColumns.filter(x => x.key !== 'options').map(x => ({
      dataIndex: x.dataIndex as string,
      title: x.title! as string
    }))
  );

  return (
    <ProTable<ILookup>
      columns={renderedColumns}
      loading={isLoading}
      request={async (params = {}, sort, filter) =>
        SendRequestForData({ params, sort, filter })
      }
      onReset={() => {
        let params = { current: 1, pageSize };
        let sort = {};
        let filter = {};
        SendRequestForData({ params, sort, filter, isReset: true });
      }}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        showQuickJumper: true,

        pageSize,
        total,
      }}
      search={{ labelWidth: "auto" }}
      dateFormatter="string"
      toolBarRender={() => [
        <Button type="primary" onClick={() => exportInstance.download("Accounts List", "xlsx")}>
          Export
        </Button>,
      ]}
    />
  );
};

export default Table;
