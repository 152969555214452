import { PageHeader } from "@ant-design/pro-layout";
import { Button } from "antd";

import Spin from "antd/es/spin";

const Header = ({
  title,
  isLoading,
  isError,
  error,
  refetch,
}: {
  title: string;
  isLoading: boolean;
  isError: boolean;
  error: string;
  refetch: () => void;
}) => {

  return (
    <PageHeader
      onBack={() => window.history.back()}
      title={title}
      subTitle={isLoading ? <Spin /> : isError ? error : null}
      extra={isError ? [<Button onClick={refetch}>Reset</Button>] : null}
    ></PageHeader>
  );
};

export default Header;
