
import Tag from "antd/es/tag";
import Space from "antd/es/space";
import Descriptions from "antd/es/descriptions";


import { ResponseType } from "pages/tasks";
import * as api from "components/api";
import { PageHeader } from "@ant-design/pro-layout";
import { ProCard } from '@ant-design/pro-components';
import { useState } from "react";

type profileHeaderProps = {
  data: ResponseType;
  options: Record<api.ILookupKeys, api.ICombo[]>;
  title: string;
};

export const ProfileHeader = ({
  data,
  title,
  options,
}: profileHeaderProps) => {
  const [description, setDescription] = useState('');


  return (
    <PageHeader
      onBack={() => window.history.back()}
      title={title}
      subTitle={`${data.name}`}
      tags={
        <Space>
          <Tag color={data.active ? "green" : "volcano"}>
            Active: {data.active ? "Yes" : "No"}
          </Tag>



        </Space>

      }
    // extra={
    //   <Space>
    //     <ActionMenu
    //       handleClick={handler}
    //       active={data.active}
    //       canAccess={data.hasUser}
    //     />
    //   </Space>
    // }
    >

      <ProCard
        title="Task Detail"
        split={'vertical'}
        bordered
        headerBordered
      >
        <ProCard colSpan="50%">



          <Descriptions size="default" column={1}>
            <Descriptions.Item label="Name">
              {data.name}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {data.email}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">{data.phoneNumber}</Descriptions.Item>
            <Descriptions.Item label="City">{data.city.name}</Descriptions.Item>
            <Descriptions.Item label="Sex">
              {data.sex.name}
            </Descriptions.Item>
          </Descriptions>

        </ProCard>
        <ProCard >

          <Descriptions size="default" column={1}>
            <Descriptions.Item label="Date Of Birth">
              {data.dateOfBirth}
            </Descriptions.Item>
            <Descriptions.Item label="Passport Number">
              {data.passportNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Country Of Origin">
              {data.countryOfOrigin}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {data.address}
            </Descriptions.Item>
          </Descriptions>
        </ProCard>
      </ProCard>
    </PageHeader>
  );
};
