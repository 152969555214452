import { useState } from "react";
import {
    ModalForm,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
} from "@ant-design/pro-form";
import message from "antd/es/message";

import Error from "components/ui/error";
import { ICombo, ILookupKeys, ISubmitionResponse } from "components/api";
import { dateTimeFormats } from "assets";

export type CustomerContactRequest = {
    name: string;
    phoneNumber: string;
    email: string;
    address: string;
    sex: number;

};

type FormProps = {
    visible: boolean;
    onClose: (visible: boolean) => void;
    lookups: Record<ILookupKeys, ICombo[]>;
    onFinish: (values: CustomerContactRequest) => Promise<ISubmitionResponse>;
    title: string;
    defaultValue: CustomerContactRequest;
};

const CustomerContactForm = (props: FormProps) => {
    const { visible, onClose, onFinish } = props;
    const [formErrors, setFormErrors] = useState<ISubmitionResponse>({
        error: null,
        isError: false,
        isSuccess: false,
    });

    const resetErrors = () => {
        if (formErrors.isError) {
            setFormErrors({
                error: null,
                isError: false,
                isSuccess: true,
            });
        }
    };

    return (
        <ModalForm<CustomerContactRequest>
            title={props.title}
            size={"middle"}
            width={400}
            visible={visible}
            autoFocusFirstInput
            request={async () => props.defaultValue}
            name="galawo-model-form"
            modalProps={{
                onCancel: () => onClose(false),
                destroyOnClose: true,
                closable: false,
                maskClosable: false,
            }}
            onFinish={async (values) => {
                var result = await onFinish(values);
                setFormErrors(result);
                if (result.isSuccess) {
                    message.success("Successfuly completed");
                    onClose(false);
                }
                return result.isSuccess;
            }}
            onChange={resetErrors}
            submitter={{
                searchConfig: {
                    resetText: "Cancel",
                    submitText: "Submit",
                },
            }}
        >
            <ProFormText
                name="name"
                label="Name"
                placeholder="Enter Name"
                rules={[{ required: true }]}
                width="xl"
            />
            <ProFormText
                name="phoneNumber"
                label="Phone Number"
                placeholder="Enter Phone Number"
                rules={[{ required: true, type: "number" }]}
                width="xl"
            />

            <ProFormText
                name="email"
                label="Email"
                placeholder="Enter Email"
                rules={[{ required: true, type: "email" }]}
                width="xl"
            />

            <ProFormTextArea
                name="address"
                label="Address"
                placeholder="Enter Address"
                rules={[{ required: true }]}
                width="xl"
            />

            <ProFormSelect
                name="sex"
                label="Sex"
                rules={[{ required: true }]}
                width="xl"
                request={async () => props.lookups.sex ?? []}
                debounceTime={0}
                showSearch={true}
                placeholder="Please select sex"
            />
            <Error error={formErrors.error} isError={formErrors.isError} />
        </ModalForm>
    );
};

export default CustomerContactForm;
