import { ILookup, ILookupKeys } from "components/api";
const discriminator = "ILookup";
const Sex: ILookup[] = [
  { id: 1, name: "Male", discriminator },
  { id: 2, name: "Female", discriminator },
];
const ResultTypes: ILookup[] = [
  { id: 1, name: "Positive", discriminator },
  { id: 2, name: "Negative", discriminator },
];
const Status: ILookup[] = [
  { id: 1, name: "New", discriminator },
  { id: 2, name: "In Progress", discriminator },
  { id: 3, name: "Due Date", discriminator },
  { id: 4, name: "Completed", discriminator },
];
const Countries: ILookup[] = [
  { id: "so", name: "Somalia", discriminator },
  { id: "sl", name: "Somaliland", discriminator },
  { id: "eg", name: "Egypt", discriminator },
];

const ProjectStatuses: ILookup[] = [
  { id: 0, name: "Progress", discriminator },
  { id: 1, name: "Ended", discriminator },
];
const ReceiptTypes: ILookup[] = [
  { id: 1, name: "Program", discriminator },
  { id: 2, name: "Admin", discriminator },
];
const LeaveStatuses: ILookup[] = [
  { id: 0, name: "New", discriminator, color: "blue" },
  { id: 1, name: "Increase", discriminator, color: "cyan" },
  { id: 2, name: "Change", discriminator, color: "pink" },
];
const FlowTypes: ILookup[] = [
  { id: 0, name: "Debit", discriminator },
  { id: 1, name: "Credit", discriminator },
];
const BidStatuses: ILookup[] = [
  { id: 0, name: "Initiated", discriminator, color: "blue" },
  { id: 1, name: "Disapproved", discriminator, color: "volcano" },
  { id: 2, name: "Approved", discriminator, color: "green" },
];
const MaritalStatus: ILookup[] = [
  { id: "Single", name: "Single", discriminator },
  { id: "Married", name: "Married", discriminator },
  { id: "Widowed", name: "Widowed", discriminator },
  { id: "Seperated", name: "Seperated", discriminator },
];
const OrderStatuses: ILookup[] = [
  { id: 0, name: "Initiated", discriminator, color: "blue" },
  { id: 1, name: "Technically Reviewed", discriminator, color: "cyan" },
  { id: 2, name: "Financially Reviewed", discriminator, color: "lime" },
  { id: 3, name: "Bids Submitted", discriminator, color: "purple" },
  { id: 4, name: "Purchased", discriminator, color: "green" },
  { id: 5, name: "Canceled", discriminator, color: "red" },
];
const ActionTypes: ILookup[] = [
  { id: 0, name: "Initiated", discriminator, color: "blue" },
  { id: 1, name: "Technical Review", discriminator, color: "cyan" },
  { id: 2, name: "Finance Review", discriminator, color: "lime" },
  { id: 3, name: "Approval", discriminator, color: "green" },
  { id: 3, name: "Disapproval", discriminator, color: "volcano" },
];
const AccountTypes: ILookup[] = [
  { id: 1, name: "Current Assets", discriminator },
  { id: 2, name: "Fixed Assets", discriminator },
  { id: 3, name: "Other Assets", discriminator },
  { id: 4, name: "Current Liabilities", discriminator },
  { id: 5, name: "Long-term Liabilities", discriminator },
  { id: 6, name: "Equity", discriminator },
  { id: 7, name: "Revenue", discriminator },
  { id: 8, name: "Expenses", discriminator },
  { id: 9, name: "Operating Expenses", discriminator },
  { id: 10, name: "Other Expenses", discriminator },
  { id: 11, name: "Taxes Paid", discriminator },
];

const ClaimValues: ILookup[] = [
  { id: "Query", name: "Query", discriminator },
  { id: "Create", name: "Create", discriminator },
  { id: "Modify", name: "Modify", discriminator },
  { id: "Delete", name: "Delete", discriminator },
  { id: "BulkUpload", name: "Bulk Upload", discriminator },
];

const EmploymentTypes: ILookup[] = [
  { id: 0, name: "Full Time", discriminator },
  { id: 1, name: "Part Time", discriminator },
  { id: 2, name: "Short Time", discriminator },
];

const PositionsTypes: ILookup[] = [
  { id: 0, name: "Staff", discriminator },
  { id: 1, name: "Volunteer", discriminator },
  { id: 2, name: "Consultant", discriminator },
];
const TransactionTypes: ILookup[] = [
  { id: 1, name: "Income", discriminator },
  { id: 2, name: "Payment", discriminator },
];

const UnitTypes: ILookup[] = [
  { id: 1, name: "PC", discriminator },
  { id: 2, name: "Person", discriminator },
  { id: 3, name: "Box", discriminator },
];

const PositionStatuses: ILookup[] = [
  { id: 0, name: "Current", discriminator },
  { id: 1, name: "Promoted", discriminator },
  { id: 2, name: "Demoted", discriminator },
  { id: 3, name: "Transferred", discriminator },
  { id: 4, name: "Transfer with Promotion", discriminator },
  { id: 5, name: "Transfer with Demotion", discriminator },
  { id: 6, name: "Salary Increase", discriminator },
  { id: 7, name: "Terminated", discriminator },
];

const values: { key: ILookupKeys; ILookups: ILookup[] }[] = [
  { key: "sex", ILookups: Sex },
  { key: "countries", ILookups: Countries },
  { key: "claimValues", ILookups: ClaimValues },
  {key : "status", ILookups: Status},
  {key : "resultTypes", ILookups: ResultTypes},
];
const enumValues = (key: string) =>
  values.find((x) => x.key === key)?.ILookups || [];
export default enumValues;
