import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { staleTime } from "assets/";
import { IPagedResponse, useAxios } from ".";
import { IErrorResult } from "./types";

type dependentProps = {
  dependsOn: string;
  dependsOnIsRequired: boolean;
};
type QueriesBLProps = {
  endpoint: string;
  dependent?: dependentProps;
  enabled?: boolean;
  query?: string;
};
function QueryBL<T>({ endpoint, dependent, enabled = true, query }: QueriesBLProps) {
  const axios = useAxios();
  const { search } = useLocation();
  const mainQueryKey = [endpoint, { search }, query];

  const enableMainQuery = () => {
    const defaults = {
      size: "size",
      index: "index",
    };
    if (!enabled) {
      return false;
    }
    if (search.includes(defaults.size) && search.includes(defaults.index)) {
      if (dependent && dependent.dependsOnIsRequired) {
        return search.includes(dependent.dependsOn);
      }
      return true;
    }
    return false;
  };

  const { isLoading, isError, data, error, isFetching, refetch } = useQuery<
    IPagedResponse<T>,
    IErrorResult
  >(
    mainQueryKey,
    async () => {
      const { data } = await axios.get(`/${endpoint}${search}${query ? query : ""}`);
      return data;
    },
    {
      keepPreviousData: true,
      retry: false,
      staleTime: staleTime,
      enabled: enableMainQuery(),
      cacheTime: 0, 
    }
  );

  return {
    isLoading,
    isError,
    data,
    error,
    isFetching,
    refetch,
    mainQueryKey,
  };
}
export default QueryBL;
