import { useNavigate, useLocation } from "react-router-dom";

import {
  Endpoints,
  ISubmitionResponse,
  useQuery,
  useLookups,
  useMutator,
  IErrorResult,
} from "components/api";
import Header from "components/ui/header";
import { IChangeRequestProps } from "pages/common";
import { useState } from "react";
import { paginateEndpoint } from "utils";
import Form from "./form";
import Table from "./table";
import { RequestType, ResponseType, FormStateProps } from "./types";
import PermissionForm, { PermissionRequestType } from "./permission-form";

const endpoint = Endpoints.Roles.main;
const initialState: RequestType = {
  id: "",
  name: "",
  description: "",
  isEnabled: false,
};
const initialStatePermission: PermissionRequestType = {
  permissionId: "",
  groupId: "",
};
const Company = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [formState, setFormState] = useState<FormStateProps>({
    visible: false,
    permissionVisible: false,
    addForm: true,
    initialState,
    initialStatePermission,
    addFormPermission: true,
  });

  const options = useLookups({
    lookups: [
      {
        endpoint: Endpoints.Permission.lookup,
        fromLookup: false,
        isLocalEnum: false,
        id: "permissions",
      },
    ],
    enabled: true,
  });

  const [locationData, setLocationData] = useState<string>("");

  const { data, isLoading, refetch, error, isError, mainQueryKey } = useQuery<
    ResponseType[]
  >({ endpoint });

  const mutations = useMutator<RequestType | ResponseType | PermissionRequestType>();

  const submitPermissions = async (payload: PermissionRequestType): Promise<ISubmitionResponse> => {
    try {
      if (formState.addFormPermission) {
        return await mutations.addHandler({
          endpoint: Endpoints.Roles.permissions(formState.initialStatePermission.groupId),
          mainQueryKey,
          payload,
        });
      }
      else {
        return await mutations.editHandler({
          endpoint: Endpoints.Roles.permissions(formState.initialStatePermission.groupId),
          mainQueryKey: mainQueryKey,
          payload,
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as IErrorResult,
      };
    }
  }

  const submit = async (payload: RequestType): Promise<ISubmitionResponse> => {
    try {
      if (formState.addForm) {
        return await mutations.addHandler({
          endpoint,
          mainQueryKey,
          payload,
        });
      } else {
        return await mutations.editHandler({
          endpoint: `${endpoint}/${formState.initialState.id}`,
          mainQueryKey: mainQueryKey,
          payload,
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as IErrorResult,
      };
    }
  };

  const formVisibleChange = (
    visible: boolean,
    permissionVisible: boolean,
    init = initialState,
    initPermission = initialStatePermission,
    addForm = true,
    addFormPermission = false
  ) => {

    // if (formState.visible === visible) {
    //   return;
    // }
    // if (formState.permissionVisible === permissionVisible) {
    //   return;
    // }
    setFormState({
      visible,
      permissionVisible,
      addForm,
      addFormPermission,
      initialState: init,
      initialStatePermission: initPermission,
    });
  };

  const editOnClickHandler = ({ isEnabled, description, name, ...rest }: ResponseType) => {
    console.log('rest', rest);

    return formVisibleChange(
      true,
      false,
      {
        ...rest,
        isEnabled,
        description,
        name,
      },
      initialStatePermission,
      false,
      false,
    );
  };
  const deleteOnClickHandler = async (id: number | string | undefined) => {
    return await mutations.deleteHandler({
      mainQueryKey: mainQueryKey,
      endpoint: endpoint + "/" + id,
    });
  };

  const addOnClickPermissionHandler = (id: string) => {
    return formVisibleChange(
      false,
      true,
      initialState,
      { permissionId: '', groupId: id },
      false,
      true,
    );
  }

  const SendRequestForData = ({
    params,
    sort,
    filter,
    isReset,
  }: IChangeRequestProps) => {
    const newLogaction = paginateEndpoint({
      endpoint,
      includeEndpoint: false,
      requestParams: { params, sort, filter },
      dependent: undefined,
      outsiderDependent: false,
    });
    console.log("current", locationData);
    console.log("next", newLogaction);

    if (locationData !== newLogaction) {
      setLocationData(newLogaction);
      navigate({
        pathname: pathname,
        search: newLogaction,
      });
    }

    return data?.data ?? [];
  };

  // if (error || isError) {
  //   return <div>Error</div>;
  // }

  return (
    <>
      <Header
        title="Roles"
        error={error?.detail ?? ""}
        isError={isError}
        isLoading={isLoading}
        refetch={refetch}
      />
      <Table
        SendRequestForData={SendRequestForData}
        addNewHandler={() => formVisibleChange(true, false)}
        dataSource={data?.data ?? []}
        deleteHandler={deleteOnClickHandler}
        editHandler={editOnClickHandler}
        isLoading={isLoading}
        pageSize={data?.size ?? 10}
        total={data?.count ?? 0}
        addPermissionHandler={addOnClickPermissionHandler}
        lookups={{
          data: {},
          isloading: false,
        }}
      />
      <Form
        title="Role"
        initialState={formState.initialState}
        lookups={{
          data: {},
          isloading: false,
        }}
        onFinish={submit}
        onVisibleChange={(visible) => formVisibleChange(visible, false)}
        state={formState.addForm ? "Add New" : "Edit"}
        visible={formState.visible}
      />

      <PermissionForm
        title="Permission"
        lookups={options.data}
        onFinish={submitPermissions}
        onClose={(visible) => formVisibleChange(false, visible)}
        defaultValue={formState.initialStatePermission}
        visible={formState.permissionVisible}
      />
    </>
  );
};

export default Company;
