import Modal from "antd/es/modal";
import Button from "antd/es/button";
import Space from "antd/es/space";
import "./index.css";

type popUpActionsProps<T> = {
  visible: boolean;
  title: string;
  id: string | number;
  payload?: T;
  visibilityChangeFunc: () => void;
  width: number;
  closable: boolean;
  onConfirm: (id: number | string, payload?: T) => void;
};
export const PopUpActions = <T,>({ ...props }: popUpActionsProps<T>) => {
  return (
    <Modal
      visible={props.visible}
      title={props.title}
      closable={props.closable}
      maskClosable
      centered
      onCancel={props.visibilityChangeFunc}
      footer={null}
      width={props.width}
    >
      <div className="space-align-container">
        <Space>
          <Button
            onClick={() => props.visibilityChangeFunc()}
            type="primary"
            size="middle"
          >
            No, Cancel
          </Button>
          <Button
            onClick={() => props.onConfirm(props.id, props.payload)}
            type="primary"
            size="middle"
            danger
          >
            Yes, Confirm
          </Button>
        </Space>
      </div>
    </Modal>
  );
};
