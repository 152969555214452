import { useState } from "react";
import {
    ModalForm,
    ProFormText,
    ProFormDatePicker,
} from "@ant-design/pro-form";
import message from "antd/es/message";

import Error from "components/ui/error";
import { ICombo, ILookupKeys, ISubmitionResponse } from "components/api";
import { dateTimeFormats } from "assets";

export type CustomerEntryRequest = {
    dateOfEntry: Date;
    dateOfDeparture: Date;
    visaNumber: string;
    purposeOfVisit: string;
};

type FormProps = {
    visible: boolean;
    onClose: (visible: boolean) => void;
    lookups: Record<ILookupKeys, ICombo[]>;
    onFinish: (values: CustomerEntryRequest) => Promise<ISubmitionResponse>;
    title: string;
    defaultValue: CustomerEntryRequest;
};

const ActionsForm = (props: FormProps) => {
    const { visible, onClose, onFinish } = props;
    const [formErrors, setFormErrors] = useState<ISubmitionResponse>({
        error: null,
        isError: false,
        isSuccess: false,
    });


    const resetErrors = () => {
        if (formErrors.isError) {
            setFormErrors({
                error: null,
                isError: false,
                isSuccess: true,
            });
        }
    };

    return (
        <ModalForm<CustomerEntryRequest>
            title={props.title}
            size={"middle"}
            width={400}
            visible={visible}
            autoFocusFirstInput
            request={async () => props.defaultValue}
            name="galawo-model-form"
            modalProps={{
                onCancel: () => onClose(false),
                destroyOnClose: true,
                closable: false,
                maskClosable: false,
            }}
            onFinish={async (values) => {
                var result = await onFinish(values);
                setFormErrors(result);
                if (result.isSuccess) {
                    message.success("Successfuly completed");
                    onClose(false);
                }
                return result.isSuccess;
            }}
            onChange={resetErrors}
            submitter={{
                searchConfig: {
                    resetText: "Cancel",
                    submitText: "Submit",
                },
            }}
        >


            <ProFormDatePicker
                name="dateOfEntry"
                label="Date of Entry"
                rules={[{ required: true }]}
                width="xl"
                fieldProps={{
                    format: dateTimeFormats.dateOnly,
                }}
            />

            <ProFormDatePicker
                name="dateOfDeparture"
                label="Date of Departure"
                rules={[{ required: true }]}
                width="xl"
                fieldProps={{
                    format: dateTimeFormats.dateOnly,
                }}
            />

            <ProFormText
                name="visaNumber"
                label="Visa Number"
                placeholder="Type Visa Number"
                rules={[{ min: 5, max: 250, required: true }]}
                width="xl"
            />

            <ProFormText
                name="purposeOfVisit"
                label="Purpose of Visit"
                placeholder="Type Purpose of Visit"
                rules={[{ min: 5, max: 250, required: true }]}
                width="xl"
            />

            <Error error={formErrors.error} isError={formErrors.isError} />
        </ModalForm>
    );
};

export default ActionsForm;
